<template>
  <div class="section1">
      <div class="main">
          <a class="bigTitle">
              <h2>ERROR</h2>
              <span>401</span>
          </a>
          <div class="content">
			  <router-link to="/index">返回主页</router-link>
          </div>
      </div>
  </div>
</template>

<script>

</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.content{
	width: 100%;text-align: center;
}
</style>
